import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom"
import { XIcon, MenuIcon } from '@heroicons/react/outline'

const links = [
    {
        name: 'Home',
        href: '/'
    },
    {
        name: 'About us',
        href: '/about/'
    },
    {
        name: 'Practices',
        href: '/practices/'
    },
    {
        name: 'Careers',
        href: '/careers/'
    },
    {
        name: 'Feedback',
        href: '/feedback/',
    },
    {
        name: 'Contact us',
        href: '/contact/'
    },
]

export default function NavLinks(){
    let location = useLocation()
    const { pathname } = location;
    const [hovered, setHovered] = useState("");
    const [clickedMenu, setClickedMenu] = useState(false);

    useEffect(() => {
        if (clickedMenu) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      }, [clickedMenu]);

    const hoveredLink = (name) => {
        setHovered(name);
    }

    const handleMenu = () => {
        setClickedMenu(!clickedMenu);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    return(
        <div>
            <div className="gap-4 items-center m-5 hidden lg:flex">
                {links.map((link) => {
                    return (
                        <div>
                            <div className="flex text-white">
                                <Link
                                    key={link.name}
                                    to={link.href}
                                    onMouseEnter={() => hoveredLink(link.name)}
                                    onMouseLeave={() => hoveredLink("")}
                                >
                                    {link.name}
                                </Link>
                            </div>
                            <div className={`border-b border-b-white transition-all duration-300 ease-in-out overflow-hidden ${pathname === link.href || hovered === link.name ? 'w-full' : 'w-0'}`}></div>
                        </div>
                    )
                })}
                <a 
                    to='/'
                    href='https://client.quilatonlawoffice.com/'
                    target='__blank'
                    className="bg-white text-black py-3 px-6 hover:bg-black hover:text-white duration-300 ease-in-out"
                >
                    LOGIN
                </a>
            </div>
            <div className='text-white sm:flex lg:hidden'>
            {clickedMenu ? (
                <div className='fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black z-50 flex flex-col items-center justify-center'>
                    <XIcon
                        onClick={() => handleMenu()}
                        className='text-white w-14 h-14 sm:w-10 sm:h-10 border-2 rounded-xl border-white p-1 absolute top-4 right-4 cursor-pointer'
                    />
                    <div className='text-center'>
                        {links.map((link) => (
                            <div key={link.name} className="mb-4">
                                <Link
                                    to={link.href}
                                    onMouseEnter={() => hoveredLink(link.name)}
                                    onMouseLeave={() => hoveredLink("")}
                                    className="text-white text-xl"
                                    onClick={() => setClickedMenu(false)}
                                >
                                {link.name}
                            </Link>
                            <div className={`border-b border-white transition-all duration-300 ease-in-out overflow-hidden ${pathname === link.href || hovered === link.name ? 'w-full' : 'w-0'}`}></div>
                            </div>
                        ))}
                        <a 
                            to='/'
                            href='https://client.quilatonlawoffice.com/'
                            target='__blank'
                            className="bg-white text-black py-3 px-6 hover:bg-black hover:text-white duration-300 ease-in-out mt-4 inline-block"
                        >
                            LOGIN
                        </a>
                    </div>
                </div>
            ) : (
                <MenuIcon
                onClick={() => handleMenu()}
                className='text-white w-14 h-14 sm:w-10 sm:h-10 border-2 rounded-xl border-white p-1 cursor-pointer'
                />
            )}
            </div>
        </div>
    )
}